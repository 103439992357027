// var loadFile = require('require-txt')
// loadFile()
// loadFile( "csv" )
// loadFile( [ "html", "html5" ] )

exports.widgets = [

   { 
      widget_type: 'html-widget',
      electronHide: false,
      mobileCols: 12,
      laptopCols: 12,
      html: require('@/mkdata/html/companyInfo.js').content,
   },

   { widget_type: 'newline-widget', height: "0px" },
   {
      widget_type: 'point-widget',
      reportName: 'temp01',
      displayName: 'Can nhiệt 1',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp02',
      displayName: 'Can nhiệt 2',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp03',
      displayName: 'Can nhiệt 3',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp04',
      displayName: 'Can nhiệt 4',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp05',
      displayName: 'Can nhiệt 5',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp06',
      displayName: 'Khí nóng',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'temp07',
      displayName: 'Khí ẩm',
      icon: 'mdi-thermometer',
      value: 0,
      color: '#2196F3',
      unit: '°C',
      mobileCols: 4,
      laptopCols: 2,
      width: "14.285%",
      valueFontSize: "20px",
      valueFontSizeMobile: "22px",
   },

   {
      widget_type: 'point-widget',
      reportName: 'buzzer',
      displayName: 'Cảnh báo sự cố',
      icon: 'mdi-bell-ring',
      control: true,
      dataType: 'BOOL',
      labels: ['', 'Cảnh báo'],
      colors: ['green', 'red'],
      value: 'OFF',
      color: '#4CAF50',
      unit: '',
      mobileCols: 8,
      laptopCols: 0,
   },

   { widget_type: 'newline-widget', height: "0px", mobileCols: 0, laptopCols: 1, },

   {
      widget_type: 'point-widget',
      reportName: 'fan1_frequency',
      // sourceControl: 'fan1_frequency_ctrl',
      displayName: 'Biến tần thải sấy',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#827717',
      unit: 'Hz',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan3_frequency',
      // sourceControl: 'fan3_frequency_ctrl',
      displayName: 'Biến tần thu hồi',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#827717',
      unit: 'Hz',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan2_frequency',
      // sourceControl: 'fan2_frequency_ctrl',
      displayName: 'Biến tần thải nung',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#827717',
      unit: 'Hz',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'accuCounterOut',
      displayName: 'Số xe ra lò sấy',
      icon: 'mdi-wheel-barrow',
      value: 0,
      color: '#3F51B5',
      unit: 'xe',
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 2,
   },

   {
      widget_type: 'point-widget',
      reportName: 'accuCounterIn',
      displayName: 'Số xe vào lò nung',
      icon: 'mdi-wheel-barrow',
      value: 0,
      color: '#3F51B5',
      valueFontSizeMobile: "22px",
      unit: 'xe',
      mobileCols: 6,
      laptopCols: 2,
   },

   {
      widget_type: 'point-widget',
      reportName: 'buzzer',
      displayName: 'Cảnh báo sự cố',
      icon: 'mdi-bell-ring',
      control: true,
      dataType: 'BOOL',
      labels: ['', 'Cảnh báo'],
      colors: ['green', 'red'],
      value: 'OFF',
      color: '#4CAF50',
      unit: '',
      mobileCols: 0,
      laptopCols: 2,
   },

   { widget_type: 'newline-widget', height: "0px", mobileCols: 0, laptopCols: 1, },
   
   { widget_type: 'spacer-widget', laptopCols: 1, mobileCols: 0, },

   {
      widget_type: 'point-widget',
      reportName: 'totalPower',
      displayName: 'Tổng công suất',
      icon: 'mdi-alpha-p',
      value: 0,
      color: '#009688',
      unit: 'kW',
      valueFontSize: "30px",
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'accuEnergy',
      displayName: 'Điện năng tiêu thụ',
      icon: 'mdi-alpha-e',
      value: 0,
      color: '#009688',
      unit: 'kWh',
      valueFontSize: "30px",
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 2,
   },

   {
      widget_type: 'point-widget',
      reportName: 'frequency',
      displayName: 'Tần số nguồn điện',
      icon: 'mdi-alpha-f',
      value: 0,
      color: '#009688',
      unit: 'Hz',
      valueFontSize: "30px",
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 2,
   },

   {
      widget_type: 'point-widget',
      reportName: 'copshi_A',
      displayName: 'Hệ số công suất',
      icon: 'mdi-alpha-a-circle',
      value: 0,
      color: '#009688',
      unit: '',
      valueFontSize: "30px",
      valueFontSizeMobile: "22px",
      mobileCols: 6,
      laptopCols: 2,
   },

   { widget_type: 'spacer-widget', laptopCols: 1, mobileCols: 0, },

   /*
   {
      widget_type: 'point-widget',
      reportName: 'DO02',
      displayName: 'Cảnh báo',
      icon: 'mdi-bell-ring',
      control: true,
      dataType: 'BOOL',
      labels: ['OFF', 'ON'],
      colors: ['green', 'red'],
      value: 'OFF',
      color: '#4CAF50',
      unit: '',
      mobileCols: 4,
      laptopCols: 2,
   },
   */

   

   { widget_type: 'newline-widget', height: "1px" },

   {
      widget_type: 'point-widget',
      reportName: 'current_A',
      displayName: 'Dòng điện pha A',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'current_B',
      displayName: 'dòng điện pha B',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'current_C',
      displayName: 'Dòng điện pha C',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'A',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },

   {
      widget_type: 'point-widget',
      reportName: 'voltage_A',
      displayName: 'Điện áp pha A',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'voltage_B',
      displayName: 'Điện áp pha B',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   {
      widget_type: 'point-widget',
      reportName: 'voltage_C',
      displayName: 'Điện áp pha C',
      icon: 'mdi-sine-wave',
      value: 0,
      defaultValue: 0,
      color: '#009688',
      unit: 'V',
      valueFontSizeMobile: "22px",
      mobileCols: 4,
      laptopCols: 2,
   },
   
   { widget_type: 'newline-widget', height: "1px" },

   { 
      widget_type: 'html-widget',
      electronHide: false,
      mobileCols: 12,
      laptopCols: 12,
      html: require('@/mkdata/html/systemInfo.js').content,
   },
]

exports.style = {
   backgroundImg: 'url("/imgs/bg0.jpg")',
   backgroundColor: '#AAAAAA',
   transparentColor: '#00000033',
   blurBackground: true,
}