exports.widgets = [
   {
      widget_type: 'point-widget',
      reportName: 'fan1_frequency',
      // sourceControl: 'fan1_frequency_ctrl',
      displayName: 'Tốc độ quạt thải sấy',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#4CAF50',
      unit: 'Hz',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan3_frequency',
      // sourceControl: 'fan3_frequency_ctrl',
      displayName: 'Tốc độ quạt thu hồi',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#03A9F4',
      unit: 'Hz',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan2_frequency',
      // sourceControl: 'fan2_frequency_ctrl',
      displayName: 'Tốc độ quạt thải nung',
      icon: 'mdi-fan',
      value: 0,
      control: true,
      color: '#FF9800',
      unit: 'Hz',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },
   { widget_type: 'newline-widget', height: "4px" },
   {
      widget_type: 'point-widget',
      reportName: 'fan1_frequency_ctrl',
      sourceDisplay: 'fan2_frequency_ctrl',
      // sourceControl: 'fan1_frequency_ctrl',
      displayName: 'Set quạt thải sấy',
      icon: '',
      value: 0,
      hidden: true,
      hiddenLabel: 'Biến trở',
      control: true,
      color: '#4CAF50',
      unit: '',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan3_frequency_ctrl',
      sourceDisplay: 'fan2_frequency_ctrl',
      // sourceControl: 'fan3_frequency_ctrl',
      displayName: 'Set quạt thu hồi',
      icon: '',
      value: 0,
      hidden: true,
      hiddenLabel: 'Biến trở',
      control: true,
      color: '#03A9F4',
      unit: '',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },
   {
      widget_type: 'point-widget',
      reportName: 'fan2_frequency_ctrl',
      sourceDisplay: 'fan2_frequency_ctrl',
      // sourceControl: 'fan2_frequency_ctrl',
      displayName: 'Set quạt thải sấy',
      icon: '',
      value: 0,
      hidden: true,
      hiddenLabel: 'Biến trở',
      control: true,
      color: '#FF9800',
      unit: '',
      mobileCols: 4,
      laptopCols: 4,
      valueFontSize: "30px",
      valueFontSizeMobile: "24px",
   },

   { widget_type: 'newline-widget', height: "1px" },

   {
      widget_type: 'chart-widget',
      title: 'Lịch sử tốc độ',
      chartType: 'line',
      points: [
         {
            reportName: 'fan1_frequency', name: 'Tốc độ quạt 1', active: true,
            scale: 1, unit: 'Hz', color: "#4CAF50", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'fan2_frequency', name: 'Tốc độ quạt 2', active: true,
            scale: 1, unit: 'Hz', color: "#FF9800", backgroundColor: "#FF000000", 
         },
         {
            reportName: 'fan3_frequency', name: 'Tốc độ quạt 3', active: true,
            scale: 1, unit: 'Hz', color: "#03A9F4", backgroundColor: "#FF000000", 
         },
      ], 
      mobileCols: 12,
      laptopCols: 12,
      minHeight: "400px",
   },

]

exports.style = {
   backgroundImg: 'url("/imgs/bg0.jpg")',
   backgroundColor: '#AAAAAA',
   transparentColor: '#00000033',
   blurBackground: true,
}